<template>
   <section class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>数据中台</el-breadcrumb-item>
            <el-breadcrumb-item>场景+</el-breadcrumb-item>
            <el-breadcrumb-item>个人画像</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>手机号：</label>
                  <el-input class="width-220"  v-model="mobileQ" placeholder="手机号" clearable></el-input>
               </el-row>
               <el-button class="bg-gradient" type="primary" icon="el-icon-search" @click="handleQuery(true)" v-text="$t('msg.search')">搜索</el-button>
               <el-button  icon="el-icon-refresh-right" plain @click="handleQuery(false)" v-text="$t('msg.reset')">重置</el-button>
            </el-row>
         </el-row>
         <!-- 主体内容 -->
         <el-row class="table-box">
            <el-table
                  ref="mainTable"
                  v-loading="loading"
                  :data="tableData"
                  :stripe="true"
                  border fit
                  >
               <el-table-column label="序号" width="60">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column prop="nickName" label="姓名" min-width="50"></el-table-column>
               <el-table-column prop="mobile" label="手机号" min-width="60"></el-table-column>
               <el-table-column label="来源" min-width="50">
                  <template slot-scope="scope">
                     {{ scope.row.userSource | filterSource }}
                  </template>
               </el-table-column>
               <el-table-column prop="industry" label="行业" min-width="60"></el-table-column>
               <el-table-column prop="medalName" label="等级" min-width="60"></el-table-column>
               <el-table-column prop="appletsCount" label="小程序使用次数"></el-table-column>
               <el-table-column prop="keyCount" label="智能开关操作次数"></el-table-column>
               <el-table-column prop="voiceCount" label="智能语音使用次数"></el-table-column>
               <el-table-column prop="temperature" label="喜欢的空调温度"></el-table-column>
               <el-table-column :label="$t('msg.operating')" fixed="right" min-width="100">
                  <template slot-scope="scope">
                     <el-button  type="text" @click="handleDetail(scope.row)" >
                        <el-link type="primary">查看个人画像</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :currentPage="page" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
         </el-row>
      </el-row>
   </section>
</template>

<script>
   import { urlObj } from '@/api/interface'
   import { mapState } from 'vuex'
   import {portrait} from "@/api/interface/data";
   export default {
      data(){
         return{
            // 数据列表
            tableData: [],             // 表格用户列表
            levelList: [],
            sourceList: [
               {label: '小程序',value: 'APPLETS'},
               {label: '自助机',value: 'SSM'},
               {label: 'APP',value: 'APP'},
               {label: 'PC端',value: 'WEB'},
               {label: '平台',value: 'PLATFORM'},
               {label: '短信',value: 'SMS'},
               {label: '邮件',value: 'EMAIL'},
               {label: '微信公众号',value: 'WECHAT_OFFICIAL'},
               {label: '微信小程序',value: 'WECHAT_APPLET'},
               {label: 'QQ',value: 'QQ'},
               {label: '支付宝',value: 'ALIPAY'},
               {label: '钉钉',value: 'DINGTALK'},
               {label: '抖音',value: 'DOUYIN'},
               {label: '小红书',value: 'XIAOHONGSHU'},
               {label: '酒店PMS',value: 'PMS'},
            ],
            tableLoad: true,           // 表格加载效果
            total: 0,                  // 用户列表总条目数
            showLabel: false,          // 显示标签
            operateType: true,         // 当前操作类型
            hotelId: '',
            certificatesList: [  // 证件类型列表
               {
                  value: 'IDCARD',
                  label: '身份证'
               }, {
                  value: 'PASSPORT',
                  label: '护照'
               }, {
                  value: 'OTHER',
                  label: '其他'
               }
            ],
            nationalityList: [   // 所属国籍
               {
                  value: '中国',
                  label: '中国'
               }, {
                  value: '其他',
                  label: '其他'
               }
            ],
            loading: true,
            // 会员查询列表
            page: 1,                   // 当前页 默认第一页
            limit: 0,                  // 每页显示数
            nickNameQ: '',             // 用户名/昵称查询
            mobileQ: '',               // 手机号
         }
      },
      computed: {
         ...mapState(['hotelInfo'])
      },
      mounted() {
         this.limit = sessionStorage.getItem('pageSize') * 1
         this.getMember()
      },
      methods: {
         // 获取会员列表
         getMember(){
            const param = {
               mobile: this.mobileQ,
               limit: this.limit,
               page: this.page,
               companyId: this.hotelInfo.storeId
            }
            const url = portrait.memberStatistics
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.loading = false
                  this.total = res.total
                  this.tableData = res.records
               }
            })
         },
         // 搜索角色
         handleQuery(bool){
            this.page = 1
            if (bool) return this.getMember()
            this.mobileQ = ''
         },
         // 改变每页数
         pageChange(num){
            this.limit = num
            this.getMember()
         },
         // 改变当前页
         handlePaging(num){
            this.page = num
            this.getMember()
         },
         // 查看详情
         handleDetail(row){
            this.$router.push({
               path: '/per_portrait',
               query: { mobile: row.mobile, name: row.nickName,
                  certificateNo: row.certificateNo, hotelId: this.hotelInfo.id }})
         }
      },
      watch: {
         hotelInfo: {
            handler(newVal, oldVal) {
               if (newVal.id !== oldVal.id && oldVal) {
                  this.getMember()
               }
            },
            deep: true
         }
      },
      filters: {
         filterSource(val){
            switch (val) {
               case 'APPLETS':
                  return val = '小程序'
               case 'SSM':
                  return val = '自助机'
               case 'APP':
                  return val = 'APP'
               case 'WEB':
                  return val = 'PC端'
               case 'PLATFORM':
                  return val = '平台'
               case 'SMS':
                  return val = '短信'
               case 'EMAIL':
                  return val = '邮件'
               case 'WECHAT_OFFICIAL':
                  return val = '微信公众号'
               case 'WECHAT_APPLET':
                  return val = '微信小程序'
               case 'QQ':
                  return val = 'QQ'
               case 'ALIPAY':
                  return val = '支付宝'
               case 'DINGTALK':
                  return val = '钉钉'
               case 'DOUYIN':
                  return val = '抖音'
               case 'XIAOHONGSHU':
                  return val = '小红书'
               case 'PMS':
                  return val = '酒店PMS'
            }
         }
      }
   }
</script>

<style lang="scss">
   .head-pic{
      display: flex;
   }
   .head-pic .old-pic{
      border-radius: 6px;
      margin-right: 8px
   }
   .head-pic .el-upload{
      width: 70px!important;
      height: 70px!important;
      line-height: 80px;
   }
   .head-pic .el-upload-list li{
      width: 70px!important;
      height: 70px!important;
   }

   /deep/ .el-tag--dark.is-hit{
      border-color: transparent;
   }
   .tag-pop .active .el-icon-check{
      display: inline;
   }
   .el-icon-check{
      display: none;
   }
   @media screen and (max-width: 1366px) {
     .cont {
       width: 1500px;
     }
   }
</style>
